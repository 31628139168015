import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import "./DropdownNumberPeople.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleAlert from "../Alert/SimpleAlert/SimpleAlert";
//import i18next from "i18next";

export interface propsR {
    tariffs: any;
    actionPeople: any;
    info: any;
    dateTour: any;
    index: any;
    ranges?: any;
}

const DropdownNumberPeople: React.FC<propsR> = ({
    tariffs,
    actionPeople,
    info,
    dateTour,
    index,
    ranges
}: propsR) => {
    //let date = new Date().toLocaleString('pt');
    //const dataNow = date.split(" ")[0].replaceAll(",", "").split("/").reverse().join("");

    //const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    const [numberAdults, setNumberAdults] = useState<number>(0);
    const [numberChilds, setNumberChilds] = useState<number>(0);
    const [numberInfants, setNumberInfants] = useState<number>(0);
    const [numberStudent, setNumberStudent] = useState<number>(0);
    const [numberElders, setNumberElders] = useState<number>(0);
    const [numberPeople, setNumberPeople] = useState<number>(1);
    const [numberTotal, setNumberTotal] = useState<number>(1);
    const [numberTotalPeople, setNumberTotalPeople] = useState<number>(1);
    const [numberStockTotal, setNumberStockTotal] = useState<number>(0);

    const [isOpen, setIsOpen] = useState<any>(false);

    const { t } = useTranslation();

    /* Limitação do número de pessoas - TICKET */
    useEffect(() => {
        tariffs.maxNumPeople = 6;
        // if (info.typeProduct === 'TICKET') {
        //     tariffs.maxNumPeople = 5;
        // } else {
        //     if (info.productCode === process.env.REACT_APP_PROMO_PROD_01) {
        //         tariffs.maxNumPeople = 5;
        //     }
        // }
    }, [tariffs, info.typeProduct, info.productCode])
    /* END - Limitação do número de pessoas - TICKET */

    //const maxNumPeople = 6;

    /* Detecta clique fora da div#wrapper para fechar*/

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* Detecta clique fora da div#wrapper para fechar\\ */

    const handleClickPeoples = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    const handleClick = (controll: string, faixa: string) => {
        let stateNumber: any;

        if (faixa === "Adult") {
            stateNumber = numberAdults;
        } else if (faixa === "Child") {
            stateNumber = numberChilds;
        } else if (faixa === "Infant") {
            stateNumber = numberInfants;
        } else if (faixa === "Elders") {
            stateNumber = numberElders;
        } else if (faixa === "Student") {
            stateNumber = numberStudent;
        } else {
            stateNumber = numberPeople;
        }

        let newNumber = numberAdults;

        if (controll === "sub") {
            if (stateNumber === 0) {
                return false;
            } else {
                newNumber = stateNumber - ranges[`increment${faixa}`];
                if (ranges[`flagStock${faixa}`] === 1) {
                    setNumberStockTotal(numberStockTotal - ranges[`increment${faixa}`]);
                }
            }
        }
        if (controll === "plus") {
            if (ranges[`flagStock${faixa}`] === 1) {
                if ((numberStockTotal + ranges[`increment${faixa}`] <= dateTour[index].maxStockNumber) && (numberStockTotal + ranges[`increment${faixa}`] <= 6)) {
                    newNumber = stateNumber + ranges[`increment${faixa}`];
                    setNumberStockTotal(numberStockTotal + ranges[`increment${faixa}`]);
                } else {
                    return false;
                }
            } else {
                newNumber = stateNumber + ranges[`increment${faixa}`];
            }
        }

        if (faixa === "Adult") {
            return setNumberAdults(newNumber);
        } else if (faixa === "Child") {
            return setNumberChilds(newNumber);
        } else if (faixa === "Infant") {
            return setNumberInfants(newNumber);
        } else if (faixa === "Elders") {
            return setNumberElders(newNumber);
        } else if (faixa === "Student") {
            return setNumberStudent(newNumber);
        } else {
            return setNumberPeople(newNumber);
        }
    };

    useEffect(() => {
        setNumberTotal(numberAdults + numberChilds + numberInfants + numberStudent + numberElders);
        setNumberTotalPeople(numberTotalPeople)
        const numberSend = {
            adults: dateTour[index].idSellingType === 1 ? 0 : numberAdults,
            childs: numberChilds,
            infants: numberInfants,
            elders: numberElders,
            student: numberStudent,
            globalPeople: dateTour[index].idSellingType === 2 ? 0 : numberPeople,
        };
        actionPeople(numberSend);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberAdults, numberChilds, numberInfants, numberPeople, numberStudent, numberElders]);

    useEffect(() => {
        setNumberTotal(numberAdults + numberChilds + numberInfants);
        setNumberTotalPeople(numberPeople);
    }, [numberAdults, numberChilds, numberInfants, numberPeople]);

    useEffect(() => {
        setNumberAdults(0);
        setNumberChilds(0);
        setNumberInfants(0);
        setNumberStudent(0);
        setNumberElders(0);
        setNumberPeople(0);
        setNumberStockTotal(0)
        setNumberTotal(0);
        //setNumberTotalPeople(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[index]?.time, dateTour[index]?.data]);

    if (isOpen === true) {
        return (
            <div id="wrapper" ref={wrapperRef}>
                <div style={{ cursor: "pointer" }} onClick={() => handleClickPeoples()}>
                    <FontAwesomeIcon
                        icon={["fal", "male"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                    />
                    <span style={{ marginLeft: "20px" }}>pessoas:</span>
                    <span
                        className="qtyTotal"
                        data-min="2"
                        data-max="19"
                        style={{ marginLeft: "5px" }}>
                        {dateTour[index].idSellingType === 2
                            ? numberTotal
                            : dateTour[index].idSellingType === 1
                                ? numberTotalPeople
                                : ""}
                    </span>
                </div>
                <div className="panel-dropdown-content right active">
                    {
                        ranges?.labelsRealSorted?.map((faixa: any, i: any) => {
                            let stateNumber: any;

                            if (faixa === "Adult") {
                                stateNumber = numberAdults;
                            } else if (faixa === "Child") {
                                stateNumber = numberChilds;
                            } else if (faixa === "Infant") {
                                stateNumber = numberInfants;
                            } else if (faixa === "Elders") {
                                stateNumber = numberElders;
                            } else if (faixa === "Student") {
                                stateNumber = numberStudent;
                            } else {
                                stateNumber = numberPeople
                            }

                            return (
                                <div key={i}>
                                    {ranges[`isActive${faixa}`] === true
                                        ?
                                        <div className="qtyButtons">
                                            <div className="flex-ajuste">
                                                <label>
                                                    <div style={{ lineHeight: ".875rem" }}>
                                                        <p className="mb-0">{ranges[`label${faixa}`]}</p>
                                                        <small id="idade-crianca" style={{ fontSize: '9px' }}> {(dateTour[index][`${faixa.toLowerCase()}Text`])}</small>
                                                    </div>
                                                    <div className="price">
                                                        {
                                                            faixa === "Private"
                                                                ?
                                                                <span
                                                                    className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                    id="valor-adulto-sm"
                                                                >
                                                                    R$ {(dateTour[index].global).toFixed(2).split(".").join(",")}
                                                                </span>
                                                                :
                                                                <span
                                                                    className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                    id="valor-adulto-sm"
                                                                >
                                                                    R$ {(dateTour[index][`${faixa.toLowerCase()}`]).toFixed(2).split(".").join(",")}
                                                                </span>
                                                        }
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="d-flex">
                                                <button className="qtyDec" onClick={() => handleClick("sub", faixa)}>
                                                    -
                                                </button>
                                                <input
                                                    type="text"
                                                    name="qtyInput"
                                                    value={stateNumber}
                                                    id="numero-adulto"
                                                />
                                                <button className="qtyInc" onClick={() => handleClick("plus", faixa)}>
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            )
                        })
                    }
                    {(numberStockTotal >=
                        dateTour[index].maxStockNumber) || (numberStockTotal >= 6) ? (
                        <SimpleAlert alertType="danger">
                            {t('slowBuy.ticket.limit')}
                        </SimpleAlert>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div style={{ cursor: "pointer" }} onClick={() => handleClickPeoples()}>
                    <FontAwesomeIcon
                        icon={["fal", "male"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                    />
                    <span style={{ marginLeft: "20px", textTransform: "lowercase" }}>{t("productOptions.people")}:</span>
                    <span
                        className="qtyTotal"
                        data-min="2"
                        data-max="19"
                        style={{ marginLeft: "5px" }}
                    >
                        {dateTour[index].idSellingType === 2
                            ? numberTotal
                            : dateTour[index].idSellingType === 1
                                ? numberTotalPeople
                                : ""}
                    </span>
                </div>
            </>
        );
    }
};

export default DropdownNumberPeople;

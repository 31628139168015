import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import i18next from "i18next";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { removeItemCart } from "../../../store/cart";

import deleteIcon from '../../../assets/icons/icon-garbage.png';
import GetPickupsSIG from "../../C2Points/GetPickupsSIG";

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export interface propAction {
  product: any;
  indexP: any;
  alert: any;
  addPickup: any;
  discountLabel: any;
  labelArrayDiscount: any;
  pricePerLabel: any;
}

const CheckoutCardTour: React.FC<propAction> = ({ product, indexP, alert, addPickup, discountLabel, labelArrayDiscount, pricePerLabel }: propAction) => {
  console.log(labelArrayDiscount)
  let totalCart = 0;

  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));
  const discount: any = discountLabel(product.productCode);

  const [, setLanguage] = useState<any>();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  let isCupomProduct: any = pricePerLabel?.length === 0 ? cartBPA?.cupom?.products?.find((elem: any) => { return (product.productCode === elem.productCode) }) : false;

  useEffect(() => {
    setLanguage(i18next.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language])

  if (product !== null) {
    let priceProduct = (
      (Number(product.adults) * Number(product.priceAdults))
      + (Number(product.childs) * Number(product.priceChilds))
      + (Number(product.infants) * Number(product.priceInfants))
      + (Number(product.elders) * Number(product.priceElders))
      + (Number(product.student) * Number(product.priceStudent))
      + (Number(product.priceGlobalPeople))
    );

    totalCart = Number(totalCart) + (priceProduct - (priceProduct * (product.discount / 100)));

    let priceProductCupom: any = 0;

    if (cartBPA?.cupom?.ticketLimit === 0) {
      priceProductCupom = cartBPA?.cupom?.type === 2 ? (totalCart - cartBPA?.cupom.value) : totalCart - ((totalCart * (cartBPA?.cupom.value / 100)));
    } else {
      priceProductCupom = totalCart;
    }

    console.log(priceProductCupom)

    return (
      <div>
        <div
          className="card my-4 rounded"
          data-product="product"
          id={product.product_code}
        >
          <div className="row p-3 pb-0">
            {/* Image */}
            <div className="col-12 col-md-3 mb-0 mb-md-3">
              <div className="">
                <img
                  src={product.imagesBaseUrl + product.imgCart}
                  className="card-img"
                  alt="..."
                />
              </div>
            </div>
            {/* Image */}
            {/* Content */}
            <div className="col-12 col-md-9">
              <div className="row checkout-product-unit">
                <div className="col-12 order-2 order-md-1 pt-3 pt-md-0">
                  <h6 className="text-uppercase">
                    {
                      product.productCode !== process.env.REACT_APP_PROMO_PROD_BF
                        ?
                        <a href={`ticket/${i18next.language === "pt-BR" ? product.slugBR : i18next.language === "en" ? product.slugEN : i18next.language === "es" ? product.slugES : product.slugBR}`} title="">
                          {i18next.language === "pt-BR" ? product.productNameBR : i18next.language === "en" ? product.productNameEN : i18next.language === "es" ? product.productNameES : product.productNameBR}
                        </a>
                        :
                        <a href={`black-friday/${i18next.language === "pt-BR" ? "bilhete-parque-bondinho-bf-br?type=ticket" : i18next.language === "en" ? "ticket-park-bondinho-bf-en?type=ticket" : i18next.language === "es" ? "billete-parque-bondinho-bf-es?type=ticket" : "bilhete-parque-bondinho-bf-br?type=ticket"}`} title="">
                          {i18next.language === "pt-BR" ? product.productNameBR : i18next.language === "en" ? product.productNameEN : i18next.language === "es" ? product.productNameES : product.productNameBR}
                        </a>
                    }
                  </h6>
                  <p>
                    <small>
                      {t("checkoutCards.cardTour.data")}:{" "}
                      {product.date.split("-").reverse().join("/")}{" "} {product?.hours?.length !== 1 ? product?.time?.split(":")[0] + ":" + product?.time?.split(":")[1] : ""}{" "}
                    </small>
                  </p>
                </div>
                <div className="close-btn">
                  <button
                    onClick={() => {
                      dispatch(removeItemCart(product));
                    }}
                  //onClick={()=>{handleClickRemove(product)}}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              </div>

              <div className="mb-3">
                {
                  product.reservationSystem === 99
                    ?
                    <GetPickupsSIG
                      propsId={product.pickupListId}
                      actionPickup={addPickup}
                      alert={alert}
                      index={indexP}
                    />
                    :
                    ""
                }
              </div>
            </div>
            {/* Content */}
          </div>
          <div className="card-bottom bg-black-10 px-3 py-3">
            <div className="row preco align-items-center">
              <div className="col-12 col-md-10">
                <small>
                  {
                    product?.ranges?.labelsRealSorted.map((faixa: any, i: any) => {
                      if (faixa !== "Private") {
                        return (
                          <span key={i}>
                            {(product[`${faixa.toLowerCase()}`] | product[`${faixa.toLowerCase()}s`]) === 0 ? "" : ` ${product.ranges[`label${faixa}`]}: ${product[`${faixa.toLowerCase()}`] | product[`${faixa.toLowerCase()}s`]} | `}
                          </span>
                        )
                      } else {
                        return (
                          <span key={i}>
                            {product[`totalPeople`] > 0 ? ` ${product.ranges[`labelPrivate`]}: ${product[`totalPeople`]} | ` : ""}
                          </span>
                        )
                      }
                    })}
                  <strong>
                    {t("checkoutCards.cardTour.total_pessoas")}: {product.adults + product.childs + product.infants + product.elders + product.student + (product.totalPeople || 0)}
                  </strong>
                </small>
              </div>
              <div className="col-12 col-md-2 text-right" style={{ lineHeight: "1rem" }}>
                {
                  cartBPA?.cupom?.products
                    ?
                    <>
                      {isCupomProduct
                        ?
                        <>
                          <OverlayTrigger
                            placement={'bottom'}
                            overlay={
                              <Tooltip id="tooltip">
                                {labelArrayDiscount.length > 0 ?
                                  labelArrayDiscount?.map((item: any) => {
                                    if (item.productCode === product.productCode) {
                                      return <><small>{`${item.label} -${item?.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}</small><br/></>;
                                    } else {
                                      return "";
                                    }
                                  }) : ""}
                              </Tooltip>
                            }>
                            <small style={{ fontSize: ".75rem", textDecoration: "line-through", cursor: 'pointer' }}>R$ {totalCart.toFixed(2).split(".").join(",")}</small>
                          </OverlayTrigger>
                          <div>
                            <span className="h6 text-muted">R$</span>
                            <span className="pl-1 h5 text-primary font-weight-bold">
                              {priceProductCupom.toFixed(2).split(".").join(",")}
                            </span>
                          </div>
                        </>
                        :
                        <>
                          <div>
                            {
                              discount
                                ?
                                <div>
                                  <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                      <Tooltip id="tooltip">
                                        {labelArrayDiscount.length > 0 ?
                                          labelArrayDiscount?.map((item: any) => {
                                            if (item.productCode === product.productCode) {
                                              return <><small>{`${item.label} -${item?.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}</small><br/></>;
                                            } else {
                                              return "";
                                            }
                                          }) : ""}
                                      </Tooltip>
                                    }>
                                    <small style={{ fontSize: ".75rem", textDecoration: "line-through", cursor: 'pointer' }}>R$ {totalCart.toFixed(2).split(".").join(",")}</small>
                                  </OverlayTrigger>
                                </div>
                                :
                                ""
                            }
                            <span className="h6 text-muted">R$</span>
                            <span className="pl-1 h5 text-primary font-weight-bold">
                              {discount ? (totalCart - discount).toFixed(2).split(".").join(",") : totalCart.toFixed(2).split(".").join(",")}
                            </span>
                          </div>
                        </>
                      }
                    </>
                    :
                    <>
                      <div>
                        {
                          discount
                            ?
                            <div>
                              <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                  <Tooltip id="tooltip">
                                    {labelArrayDiscount.length > 0 ?
                                      labelArrayDiscount?.map((item: any) => {
                                        if (item.productCode === product.productCode) {
                                          return <><small>{`${item.label} -${item?.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}</small><br/></>;
                                        } else {
                                          return "";
                                        }
                                      }) : ""}
                                  </Tooltip>
                                }>
                                <small style={{ fontSize: ".75rem", textDecoration: "line-through", cursor: 'pointer' }}>R$ {totalCart.toFixed(2).split(".").join(",")}</small>
                              </OverlayTrigger>
                            </div>
                            :
                            ""
                        }
                        <span className="h6 text-muted">R$</span>
                        <span className="pl-1 h5 text-primary font-weight-bold">
                          {discount ? (totalCart - discount).toFixed(2).split(".").join(",") : totalCart.toFixed(2).split(".").join(",")}
                        </span>
                      </div>
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CheckoutCardTour;


import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { updateCart } from "../../store/cart";
import SimpleAlertCoupon from "../Alert/SimpleAlertCoupon/SimpleAlertCoupon";

function DiscountCoupon(props: any) {
  const { t } = useTranslation();
  const { userModel }: any = JSON.parse(
    localStorage.getItem("bpasiteAuth") || "{}"
  );

  const [modalMessage, setModalMessage] = useState<any>("Carregando");
  const [modalState, setModalState] = useState<any>('');
  const [show, setShow] = useState<any>(false);

  const [listCode, setListCode] = useState<string[]>([]);

  const cartBPA: any = JSON.parse(
    JSON.stringify(useAppSelector((state: any) => state.cart))
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    let aux: any = [];
    cartBPA.dados.forEach((item: any) => {
      aux.push(
        {
          productCode: item.productCode,
          label: {
            adults: item.adults,
            childs: item.childs,
            infants: item.infants,
            elders: item.elders,
            student: item.student,
          }
        }
      );
    });

    setListCode(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateTourCode = async (code: any) => {
    setShow(false);
    setModalState('');
    setModalMessage("Carregando...");

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    try {
      const res = await api.post(`/api/TourCode/ValidateCoupon/${(encodeURI(code))}`, {
        CodeName: code,
        Products: listCode,
        userEmail: userModel?.email,
      }, config);

      if (res?.data?.status !== 400) {
        if (res?.data?.data === null) {
          setShow(true);
          setModalState("error");
          setModalMessage("Cupom inválido");
        } else {

          if(res.data.data.products) {
            /* [START] Verifica se, dentro dos produtos selecionados, existe algum bilhete/faixa válido nesse cupom */
            let hasSomeCoupomApplied: boolean = false;
  
            res.data.data.products?.forEach((elem: any) => {
              let actualProduct: any = listCode?.find((findElem: any) => { return (findElem.productCode === elem.productCode) })
              if (elem.appliedByLabel) {
                if (
                  (elem.adultApplied && actualProduct?.label?.adults > 0) ||
                  (elem.childApplied && actualProduct?.label?.childs > 0) ||
                  (elem.infantApplied && actualProduct?.label?.infants > 0) ||
                  (elem.elderApplied && actualProduct?.label?.elders > 0) ||
                  (elem.studentApplied && actualProduct?.label?.student > 0)
                ) {
                  hasSomeCoupomApplied = true;
                }
              } else {
                hasSomeCoupomApplied = true;
              }
            })
  
            if (!hasSomeCoupomApplied) {
              setShow(true);
              setModalState("error");
              setModalMessage("Nenhum dos bilhetes fornecidos está associado ao cupom.");
              return;
            }
            /* [END] */
          }

          setShow(true);
          setModalState("success");
          setModalMessage("Cupom adicionado com sucesso");

          //var cupom: any;
          if (res.data.data.typeDiscount === 2) {
            cartBPA.cupom = {
              type: res?.data?.data?.typeDiscount,
              code: res?.data?.data?.code,
              codeName: res?.data?.data?.codeName,
              value: res?.data?.data?.valueDiscount,
              products: res?.data?.data?.products,
              ticketLimit: res?.data?.data?.ticketsLimit,
              labelsApplied: [],
            };
            cartBPA.discount = res?.data?.data?.valueDiscount;
            cartBPA.tourCode = undefined;

            dispatch(updateCart(cartBPA));
          } else if (res.data.data.typeDiscount === 3) {
            cartBPA.cupom = {
              type: res?.data?.data?.typeDiscount,
              code: res?.data?.data?.code,
              codeName: res?.data?.data?.codeName,
              value: res?.data?.data?.valueDiscount,
              products: res?.data?.data?.products,
              ticketLimit: res?.data?.data?.ticketsLimit,
              labelsApplied: [],
            };
            cartBPA.discount = res?.data?.data?.valueDiscount;
            cartBPA.tourCode = undefined;

            //verifyAllCart();
          } else if (res?.data?.data?.typeDiscount === 1) {
            cartBPA.cupom = {
              type: res?.data?.data?.typeDiscount,
              code: res?.data?.data?.code,
              codeName: res?.data?.data?.codeName,
              value: res?.data?.data?.valueDiscount,
              products: res?.data?.data?.products,
              ticketLimit: res?.data?.data?.ticketsLimit,
              labelsApplied: [],
            };
            cartBPA.discount = res?.data.data?.valueDiscount;
            cartBPA.tourCode = undefined;

            dispatch(updateCart(cartBPA));
            //dispatch(updateCupomCart(cupom));
          }
        }
      }
    } catch (error: any) {
      setShow(true);
      setModalState("error");
      if (code === "") {
        setModalMessage("Cupom inválido");
      } else {
        setModalMessage(error?.response?.data?.errorMessage?.replace("Erro ao processar operação: ", "") || "rro ao processar operação");
      }
    }
  };


  function onSubmit(e: any) {
    e.preventDefault();

    validateTourCode(e.target.cupom.value);
  }

  useEffect(() => {
    setShow(false);
  }, [props.stepCtrl]);

  return (
    <div className="card-cupom">
      <Accordion defaultActiveKey="1"/*  onClick={() => setShow(false)} */>
        <Accordion.Item eventKey="0" className="checkout-shadow">
          <div className="p-2">
            <Accordion.Header>{t("checkoutSteps.titleCupom")}</Accordion.Header>
          </div>
          <Accordion.Body>
            <form onSubmit={onSubmit}>
              <input
                type="text"
                name="cupom"
                placeholder={t("checkoutSteps.placeholderCupom")}
                className="checkout-cupoms"
              />
              <SimpleAlertCoupon alertType={modalState} setShow={setShow} show={show}>{modalMessage}</SimpleAlertCoupon>
              <button type="submit" className="btn text-white checkout-cta mt-3">
                {t("checkoutSteps.apply")}
              </button>
            </form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default DiscountCoupon;
